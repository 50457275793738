import { Controller } from "@hotwired/stimulus";

import { loadTomSelect } from "~/libs/tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {
  connect() {
    if (this.element.classList.contains("tomselected")) return;
    if (this.element.classList.contains("no-ts")) return;

    loadTomSelect(this.element, this.element.classList.contains("tomselect-ajax"));
  }
}
